import { useNavigate } from '@remix-run/react';
import { useMemo, useState } from 'react';
import { Waypoint } from 'react-waypoint';

import { useListLoader } from '../../hooks/useListLoader';
import { apiService } from '../../services/api-service';
import {
  type Event,
  type EventsResponse,
  type EventType,
  type QueryEventsRequest,
} from '../../services/api-service/event.api';
import { OrgSubscriptionUtils } from '../../types';
import { assertExhaustive } from '../../utils/common';
import { ErrorMessage } from '../Game/GameCenter';
import { ReplaceIcon } from '../icons/ReplaceIcon';
import { Loading } from '../Loading';
import { OrganizationSelect } from '../Organization';
import { IntakeFormColumn } from './IntakeForm';
import { eventFieldString, EventUtils } from './utils';

type EventSortBy =
  | 'organization'
  | 'organizer'
  | 'date'
  | 'game pack'
  | 'host'
  | 'studio'
  | 'venue'
  | 'players'
  | 'duration';

const EventCompare = (a: Event, b: Event, sortBy: EventSortBy): number => {
  switch (sortBy) {
    case 'organization':
      return eventFieldString(a, 'organization').localeCompare(
        eventFieldString(b, 'organization')
      );
    case 'organizer':
      return eventFieldString(a, 'organizer').localeCompare(
        eventFieldString(b, 'organizer')
      );
    case 'date':
      return a.startAt.localeCompare(b.startAt);
    case 'game pack':
      return a.gamePack.name.localeCompare(b.gamePack.name);
    case 'host':
      return (a.host?.name ?? '').localeCompare(b.host?.name ?? '');
    case 'studio':
      return (a.studio?.name ?? '').localeCompare(b.studio?.name ?? '');
    case 'venue':
      return (a.venue?.name ?? '').localeCompare(b.venue?.name ?? '');
    case 'players':
      return (a.attendees?.length ?? 0) - (b.attendees?.length ?? 0);
    case 'duration':
      return (
        new Date(a.endAt).getTime() -
        new Date(a.startAt).getTime() -
        (new Date(b.endAt).getTime() - new Date(b.startAt).getTime())
      );
    default:
      assertExhaustive(sortBy);
      return 0;
  }
};

const EventsTable = (props: { req: QueryEventsRequest }): JSX.Element => {
  const { req } = props;
  const { type } = req;
  const [reversed, setReversed] = useState(true);
  const [sortBy, setSortBy] = useState<EventSortBy>('date');

  const paginator = useMemo(() => apiService.event.queryEvents(req), [req]);
  const { items, state, error, handleLoadMore, handleRetry } = useListLoader<
    EventsResponse,
    Event
  >(paginator, (a, b) => a.id === b.id);

  const showEmptyMsg =
    state.isDone && !error && items.length === 0 && !paginator.hasMore();
  const canLoadMore = state.isDone && !error && paginator.hasMore();

  const ordered = useMemo(() => {
    const sorted = items.sort((a, b) => EventCompare(a, b, sortBy));
    return reversed ? sorted.reverse() : sorted;
  }, [items, reversed, sortBy]);

  const handleClickSort = (target: EventSortBy) => {
    if (sortBy === target) {
      setReversed(!reversed);
      return;
    }

    setSortBy(target);
    setReversed(true);
  };

  return (
    <div>
      <table className='w-full'>
        <thead>
          <tr className='text-left h-14'>
            <th onClick={() => handleClickSort('organization')}>
              <div className='flex flex-row items-center hover:cursor-pointer'>
                <div className='pr-1'>Organization </div> <ReplaceIcon />
              </div>
            </th>
            {type === 'live' && (
              <th>
                <div className='flex flex-row items-center'>
                  <div className='pr-1'>Plan</div>
                </div>
              </th>
            )}
            <th className='' onClick={() => handleClickSort('organizer')}>
              <div className='flex flex-row items-center hover:cursor-pointer'>
                <div className='pr-1'>Organizer </div> <ReplaceIcon />
              </div>
            </th>
            <th onClick={() => handleClickSort('date')}>
              <div className='flex flex-row items-center hover:cursor-pointer'>
                <div className='pr-1'>Date </div> <ReplaceIcon />
              </div>
            </th>
            <th onClick={() => handleClickSort('game pack')}>
              {' '}
              <div className='flex flex-row items-center hover:cursor-pointer'>
                <div className='pr-1'>Game Pack </div> <ReplaceIcon />
              </div>
            </th>
            {type === 'live' && (
              <th onClick={() => handleClickSort('host')}>
                <div className='flex flex-row items-center hover:cursor-pointer'>
                  <div className='pr-1'>Host </div> <ReplaceIcon />
                </div>
              </th>
            )}
            {type === 'live' && (
              <th onClick={() => handleClickSort('studio')}>
                <div className='flex flex-row items-center hover:cursor-pointer'>
                  <div className='pr-1'>Studio </div> <ReplaceIcon />
                </div>
              </th>
            )}
            {type === 'live' && (
              <th onClick={() => handleClickSort('venue')}>
                <div className='flex flex-row items-center hover:cursor-pointer'>
                  <div className='pr-1'>Venue </div> <ReplaceIcon />
                </div>
              </th>
            )}
            {type === 'ond' && (
              <th onClick={() => handleClickSort('players')}>
                <div className='flex flex-row items-center hover:cursor-pointer'>
                  <div className='pr-1'>Players </div> <ReplaceIcon />
                </div>
              </th>
            )}
            {type === 'ond' && (
              <th onClick={() => handleClickSort('duration')}>
                <div className='flex flex-row items-center hover:cursor-pointer'>
                  <div className='pr-1'>Duration </div> <ReplaceIcon />
                </div>
              </th>
            )}
            {type === 'live' && (
              <th>
                <div className='flex flex-row items-center'>
                  <div className='pr-1'>Intake</div>
                </div>
              </th>
            )}
          </tr>
        </thead>
        <tbody className='text-sm'>
          {ordered.map((i) => (
            <EventItem key={i.id} event={i} />
          ))}
        </tbody>
      </table>
      <div>
        {state.isRunning && (
          <div className='flex items-center justify-center mt-50'>
            <Loading />
          </div>
        )}
        {error && (
          <div className='w-full flex items-center justify-center text-white mt-50'>
            <ErrorMessage
              text='Something went wrong'
              handleRetry={handleRetry}
            />
          </div>
        )}
        {showEmptyMsg && (
          <div className='w-full flex items-center justify-center text-white mt-50'>
            No Events.
          </div>
        )}
        {canLoadMore && (
          <Waypoint onEnter={handleLoadMore} fireOnRapidScroll>
            <div>&nbsp;</div>
          </Waypoint>
        )}
      </div>
    </div>
  );
};

const EventItem = (props: { event: Event }): JSX.Element => {
  const navigate = useNavigate();
  const { event } = props;
  const planName = useMemo(() => {
    return OrgSubscriptionUtils.GetSubscriptionPlanName(
      event.organizer?.organization
    );
  }, [event.organizer?.organization]);

  return (
    <tr
      className={`text-left h-8 hover:bg-lp-gray-002 cursor-pointer ${
        Date.now() > new Date(event.startAt).getTime()
          ? 'text-secondary'
          : event.status === 'canceled'
          ? 'text-red-002'
          : ''
      }`}
      key={event.id}
      onClick={() => navigate(`/admin/toolkit/events/${event.id}/edit`)}
    >
      <td>{eventFieldString(event, 'organization')}</td>
      {event.type === 'live' && <td>{planName}</td>}
      <td>{eventFieldString(event, 'organizer')}</td>
      <td>{EventUtils.NYTime(event.startAt)}</td>
      <td>{event.gamePack?.name}</td>
      {event.type === 'live' && <td>{event.host?.name}</td>}
      {event.type === 'live' && <td>{event.studio?.name}</td>}
      {event.type === 'live' && <td>{event.venue?.name ?? event.venue?.id}</td>}
      {event.type === 'ond' && <td>{event.attendees?.length ?? 0}</td>}
      {event.type === 'ond' && (
        <td>
          {(new Date(event.endAt).getTime() -
            new Date(event.startAt).getTime()) /
            1000 /
            60}{' '}
          min
        </td>
      )}
      {event.type === 'live' && (
        <td>
          <IntakeFormColumn event={event} />
        </td>
      )}
    </tr>
  );
};

export const AdminEventList = (): JSX.Element => {
  const [menuKey, setMenuKey] = useState<EventType>('live');
  const [orgId, setOrgId] = useState<string | null>(null);
  const navigate = useNavigate();

  const req = useMemo(
    () =>
      ({
        type: menuKey,
        orgId,
      } satisfies QueryEventsRequest),
    [menuKey, orgId]
  );

  return (
    <div className=' w-full h-full overflow-y-auto scrollbar-hide bg-game-library bg-w-full bg-no-repeat bg-top bg-local'>
      <div className='text-white px-10 pb-10 flex flex-col'>
        <div className='flex justify-between items-center'>
          <OrganizationSelect
            orgId={orgId}
            onChange={(org) => setOrgId(org?.id || null)}
            fakeOrgLabel='Non-Subscriber'
            isClearable
          />

          <div className='flex flex-col gap-1 items-center justify-center'>
            <button
              type='button'
              className='btn-primary w-52 h-10'
              onClick={() => navigate(`/admin/toolkit/events/create`)}
            >
              Schedule New Event
            </button>
          </div>
        </div>

        <div className='mt-6 flex flex-cow border-b border-secondary'>
          <div className='pb-1 pr-10'>
            <button
              type='button'
              className={`btn rounded-none ${
                menuKey === 'live' ? 'font-bold' : 'font-normal text-secondary'
              }`}
              onClick={() => {
                setMenuKey('live');
              }}
            >
              Live Games
            </button>
          </div>

          <div className=''>
            <button
              type='button'
              className={`btn rounded-none ${
                menuKey === 'ond' ? 'font-bold' : 'font-normal text-secondary'
              }`}
              onClick={() => {
                setMenuKey('ond');
              }}
            >
              On Demand Games
            </button>
          </div>
        </div>

        <EventsTable req={req} />
      </div>
    </div>
  );
};
