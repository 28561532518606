import { AdminEventList } from '../components/Event/AdminEventList';

function EventsListRoute() {
  return <AdminEventList />;
}

export const Component = EventsListRoute;

export function clientLoader() {
  return null;
}
